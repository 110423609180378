@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer components {
  .navItem {
    @apply text-base leading-[19.2px] font-normal  tracking-[5%] border-right-[2px] 
  }
}  */

@layer components {
  .navitem {
    @apply text-base font-sans leading-[19.2px] font-normal  text-center  p-1 border-black px-4 cursor-pointer;
  }

  .homeHeading {
    @apply text-[80px]  max-lg:text-[40px] font-sans w-[756px]  max-xl:w-full leading-[120%]   font-semibold  max-lg:tracking-[4px]  border-black;
  }

  .text {
    @apply text-[80px] font-sans leading-[96px] max-md:leading-[48px] font-bold tracking-widest;
  }
  .shadow1 {
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.12);
  }
  .shadow2 {
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.24);
  }
  .para {
    @apply font-sans  font-normal text-[20px] leading-[30px]  tracking-[2px] max-sm:leading-[150%] max-sm:tracking-[2px];
  }
  .section {
    @apply mt-10;
  }
  .tableheading {
    @apply bg-black para p-1 px-4 py-2 w-[320px] text-white;
  }
  .tableheading2 {
    @apply bg-black para p-1 px-4 py-2 w-[140px] text-white;
  }
  .tableheading3 {
    @apply bg-black para p-1 px-4 py-2 w-[159px] text-white border-t-2 border-white;
  }
  .tabledata {
    @apply bg-white tableheading h-[80px] text-[20px] text-[#4D4D4D] flex justify-center items-center text-center;
  }
  
  .tabledata2 {
    @apply bg-white tableheading2 h-[60px] text-[20px] text-[#4D4D4D] flex justify-center items-center text-center;
  }
  .tabledata3 {
    @apply bg-white tableheading2 w-[159px] h-[60px] text-[20px] text-[#4D4D4D] flex justify-center items-center text-center;
  }
  .formInput {
    @apply border-black border-b-2 pb-4 text-[24px] w-[460px] max-md:w-full outline-none  placeholder:para placeholder:text-black;
  }

  .list {
    @apply text-[24px]  leading-[30px] font-semibold font-sans tracking-[2.4px] max-sm:leading-[120%] max-sm:tracking-[2px];
  }
  .text_40 {
    @apply text-[40px] font-semibold leading-[120%] tracking-[2.4px] font-sans;
  }
  .text_120 {
    @apply max-lg:w-full  opacity-30 h-[120px]  max-lg:h-fit text-[#FFFFFF] font-semibold text-[120px] max-lg:text-[80px] max-sm:text-[40px]  leading-[120%] tracking-widest max-lg:tracking-wide;
  }

  a {
    -webkit-tap-highlight-color: transparent;
  }

  .admintable {
    @apply border-x-[1px] py-4 text-center;
  }

  .admintable2 {
    @apply border-l-[1px] py-4 text-center;
  }

  .autoplay-progress {
  
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
  }
  .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: white;
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }

  .gradient-background {
    background: linear-gradient(to right, #eb1d36 5%, #146C94, #19A7CE, #82cd47, #329732, #ffbe0b, #ff8400); /* Add a comma between #19A7CE and #82cd47 */
    -webkit-background-clip: text;
    color: transparent;
  }
}
